// src/components/About.js
import React from 'react';

const Footer = () => {
    return (
        <div className="">
            <div className="row px-md-5 px-sm-1">
                <div className="col-md-2 py-3">
                    <h5 className="text-white"><strong>Richiest.com</strong></h5>
                </div>
                <div className="col-md-2 py-3">
                    <h5 className="text-white"><strong>Bond ETFs</strong></h5>

                    <a className="footer-link text-white" href="/stocks/dividend/bil.html">BIL - the SPDR Bloomberg 1-3 Month
                        T-Bill ETF</a><br />
                </div>
                <div className="col-md-2 py-3">
                    <h5 className="text-white"><strong>Divident ETFs</strong></h5>

                    <a className="footer-link text-white" href="/stocks/dividend/dgro.html">DGRO - the iShares Core Dividend
                        Growth ETF</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/dgrw.html">DGRW - WisdomTree
                        Global High Growth Dividend ETF</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/divo.html">DIVO - Amplify CWP Enhanced
                        Dividend Income ETF</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/dln.html">DLN - the WisdomTree U.S. LargeCap
                        Dividend Fund</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/don.html">DON - WisdomTree U.S. MidCap
                        Dividend Fund</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/dvy.html">DVY - the iShares Select Dividend
                        ETF</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/hdv.html">HDV - iShares Core High Dividend
                        ETF</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/jepi.html">JEPI - the JPMorgan Equity
                        Premium Income ETF </a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/jepq2.html">JEPQ - JPMorgan Nasdaq Equity
                        Premium Income ETF</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/nobl.html">NOBL - ProShares S&P 500 Dividend
                        Aristocrats ETF</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/schd.html">SCHD - Schwab US Dividend Equity
                        ETF</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/sdy.html">SDY - SPDR® S&P® Dividend
                        Aristocrats™ ETF</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/sphd.html">SPHD - Invesco S&P 500 High
                        Dividend Low Volatility ETF</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/vdy.html">VDY - Vanguard FTSE Canadian High
                        Dividend Yield Index ETF</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/vti.html">VTI - the Vanguard Total Stock
                        Market ETF</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/vym.html">VYM -Vanguard High Dividend Yield
                        ETF</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/vymi.html">VYMI - the Vanguard International
                        High Dividend Yield ETF</a><br />
                </div>
                <div className="col-md-2 py-3">
                    <h5 className="text-white"><strong>Divident Stocks</strong></h5>
                    <a className="footer-link text-white" href="/stocks/dividend/aapl.html">AAPL - Apple</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/agnc.html">AGNC - AGNC Investment Corp.</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/arcc.html">ARCC - Ares Capital
                        Corporation</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/ctra.html">CTRA - Cotera Energy</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/cvs.html">CVS - CVS Health</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/epr.html">EPR - EPR Properties</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/ibm.html">IBM - International Business
                        Machines Corp.</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/infy.html">INFY - Infosys Ltd.</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/jnj.html">JNJ - Johnson & Johnson</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/key.html">KEY - KeyCorp</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/ko.html">KO - Coca-Cola</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/mo.html">MO - Altria Group, Inc.</a><br />

                    <a className="footer-link text-white" href="/stocks/dividend/mplx.html">MPLX - MPLX LP</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/msft.html">MSFT - Microsoft</a><br />

                    <a className="footer-link text-white" href="/stocks/dividend/o.html">O - Realty Income</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/oke.html">OKE - ONEOK, Inc.</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/pg.html">PG - Procter & Gamble</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/pxd.html">PXD - Pioneer Natural
                        Resources</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/sblk.html">SBLK - Star Bulk Carriers</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/sjt.html">SJT - San Juan Basin Royalty
                        Trust</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/t.html">T - AT&T</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/vici.html">VICI - VICI Properties</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/vz.html">VZ - Verizon</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/xom.html">XOM - ExxonMobil</a><br />
                    <a className="footer-link text-white" href="/stocks/dividend/zim.html">ZIM - ZIM Integrated Shipping
                        Services
                        Ltd</a><br />
                </div>
                <div className="col-md-2 py-3">
                    <h5 className="text-white"><strong>Sectors</strong></h5>
                    <a className="footer-link text-white" href="/posts/investing-basicmaterials2.html">Basic Materials</a><br />
                    <a className="footer-link text-white" href="/posts/investing-communicationservices.html">Communication
                        Services</a><br />
                    <a className="footer-link text-white" href="/posts/investing-consumercyclical.html">Consumer
                        Cyclicals</a><br />
                    <a className="footer-link text-white" href="/posts/investing-consumerdefensive.html">Consumer
                        Defensive</a><br />
                    <a className="footer-link text-white" href="/posts/investing-energy.html">Energy</a><br />
                    <a className="footer-link text-white" href="/posts/investing-financial.html">Financial</a><br />

                    <a className="footer-link text-white" href="/posts/investing-healthcare.html">Healthcare</a><br />
                    <a className="footer-link text-white" href="/posts/investing-industrials.html">Industrials</a><br />

                    <a className="footer-link text-white" href="/posts/investing-realestate.html">Real Estate</a><br />
                    <a className="footer-link text-white" href="/posts/investing-technology.html">Technology</a><br />
                    <a className="footer-link text-white" href="/posts/investing-utilities.html">Utilities</a><br />
                </div>
                <div className="col-md-2 py-3">
                    <h5 className="text-white"><strong>Help & Info</strong></h5>
                    <a className="footer-link text-white" href="/about.html">About Us</a><br />
                    <a className="footer-link text-white" href="/contact.html">Contact Us</a><br />
                </div>
            </div>
            <div className="row px-md-5 px-sm-1">
                <div className="col">
                    <p className="text-white">&copy; 2024 Richiest.com. All rights reserved. </p>

                </div>
            </div>
        </div>
    );
};

export default Footer;
