// src/components/Home.js
import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Subscribe from "../components/Subscribe";

import "../index.css";


const Home = () => {
    return (
        <div>
            <div className="bg-light">
                <div className="container-1280">
                    <a href="#" id="return-to-top"><i className="fas fa-chevron-up"></i></a>
                    <div className="row bg-white px-md-5 px-sm-0 sticky-top m-0">
                        <div className="col m-0">
                            <Nav />
                        </div>
                    </div>
                    <div className="row dark-div py-5 m-0">
                        <div className="col text-center p-4">
                            <h1 className="text-white"><strong>Be the Richiest you can</strong></h1>
                        </div>
                    </div>
                    <div className="row py-4 px-md-5 px-sm-1 bg-white m-0">
                        <div className="col-12">
                            <div className="row mt-5 mb-2 m-0">
                                <h3><strong>What sets us apart?</strong></h3>
                            </div>

                            <div className="row bg-white px-md-5 px-sm-1">
                                <div className="h-100 col-md-4 col-sm-12 p-3">
                                    <div className="p-4">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/makemoney-2eb29.appspot.com/o/richiest%2Fposts%2Fblog1%2FIcons%2Fchart-histogram.png?alt=media&token=5c53cfb0-256a-4650-9d49-2833058fc45f"
                                            alt="Icon 1" style={{ width: "30px", height: "30px", marginBottom: "20px" }} />

                                        <div>
                                            <h5><strong>Why Richiest.com Stands Out</strong></h5>
                                            <p>Expert Analysis: Our team consists of experienced financial professionals who deliver
                                                trustworthy
                                                information.</p>
                                            <p>Clear and simple Guidance: We simplify complex market trends and terminology, making
                                                stock market investing more
                                                approachable for everyone.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="h-100 col-md-4 col-sm-12 p-3">
                                    <div className="p-4">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/makemoney-2eb29.appspot.com/o/richiest%2Fposts%2Fblog1%2FIcons%2Fbriefcase%20(1).png?alt=media&token=2a82f47d-5ad4-48ea-aeff-d46990428b2b"
                                            alt="Icon 1" style={{ width: "30px", height: "30px", marginBottom: "20px" }} />

                                        <div className="m-0 p-0">
                                            <h5><strong>Commitment to Your Financial Goals</strong></h5>
                                            <p>We are dedicated to providing you with comprehensive resources and tools to
                                                make well-informed investment decisions. </p>
                                            <p>
                                                At Richiest.com, our focus is not
                                                just to analysis but to support you in achieving your financial
                                                objectives.</p>

                                        </div>
                                    </div>

                                </div>

                                <div className="h-100 col-md-4 col-sm-12 p-3">
                                    <div className="p-4">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/makemoney-2eb29.appspot.com/o/richiest%2Fposts%2Fblog1%2FIcons%2Fhand-holding-usd.png?alt=media&token=e568f2bc-c280-4754-bd55-4985ca80471a"
                                            alt="Icon 1" style={{ width: "30px", height: "30px", marginBottom: "20px" }} />

                                        <h5><strong>Build Your Wealth with Confidence</strong></h5>
                                        <p>We are here to assist you with your investment needs. Visit our website for
                                            more information, and do not hesitate to reach out to us while on your investment
                                            journey.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5 mb-2 px-md-5 px-sm-1 m-0">
                        <div className="col">
                            <h3 className=""><strong>Broad Market Indices</strong></h3>
                        </div>
                    </div>
                    <div className="row mt-3 px-md-5 px-sm-1 m-0">
                        <div className="col">


                        </div>

                    </div>

                    <div className="row mt-3 px-md-5 px-sm-1 m-0">
                        <div className="col">


                        </div>

                    </div>

                    <div className="row mt-5 mb-2 px-md-5 px-sm-1 m-0">
                        <div className="col">
                            <h3 className=""><strong>News & Highlights</strong></h3>
                        </div>
                    </div>
                    <div className="row pb-5 px-md-5 px-sm-1 m-0">
                        <div className="col-md-8 col-sm-12">

                        </div>
                        <div className="col-md-4 col-sm-12">

                            <div className="tradingview-widget-container">
                                <div className="tradingview-widget-container__widget"></div>

                            </div>
                        </div>
                    </div>
                    <div className="row dark-div m-0">
                        <div className="col">
                            <Subscribe />
                        </div>
                    </div>
                    <div className="row mt-5 mb-2 px-md-5 px-sm-1 m-0">
                        <div className="col">
                            <h3 className=""><strong>Articles</strong></h3>
                        </div>
                    </div>
                    <div className="row pb-5 px-md-5 px-sm-1 m-0">
                        <div className="col">
                            <div className="row mt-3">
                                <div className="row mx-2 justify-content-center">
                                    <div className="col-md-4 col-sm-12">
                                        <div className="mb-2">
                                            <div className="card-body px-4 py-2 bg-white h-100">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/makemoney-2eb29.appspot.com/o/richiest%2Fposts%2Fblog1%2FDALL%C2%B7E%202023-12-05%2016.32.10%20-%20A%20high-resolution%20image%20of%20an%20adult%20Caucasian%20woman%20in%20casual%20clothing%20sitting%20at%20a%20desk%20with%20a%20laptop.%20The%20laptop%20screen%20is%20turned%20away%20or%20blurred%2C%20n%20(1).png?alt=media&token=f3bbf5fd-cc45-4085-9b72-bb1bc6617996"
                                                    className="img-fluid py-2" alt="Descriptive alt text here" />
                                                <h5 className="card-title my-3"><strong>Investing in Utilities</strong></h5>
                                                <div className="mb-2">
                                                    Explore the stability and potential of utility sector investments, a guide
                                                    to
                                                    making informed decisions in this essential industry.
                                                </div>
                                                <div className="row py-4 d-flex align-items-center">
                                                    <div className="col-7">
                                                        <p className="author-text">Author: Michael D. Ashley</p>
                                                    </div>
                                                    <div className="col-5">
                                                        <a className="blog-link" href="/sectors/investing-utilities.html">Read
                                                            More&nbsp;&nbsp;</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <div className="mb-2">
                                            <div className="card-body px-4 py-2 bg-white h-100">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/makemoney-2eb29.appspot.com/o/richiest%2Fposts%2Fblog1%2FDALL%C2%B7E%202023-12-06%2016.34.20%20-%20A%20middle-aged%20man%20in%20casual%20clothing%2C%20standing%20confidently%20in%20front%20of%20a%20modern%20office%20building.%20He%20is%20holding%20a%20newspaper%20with%20a%20headline%20about%20real%20%20(1).png?alt=media&token=11734fdf-53d2-4c8e-9721-f173b9479ef9"
                                                    className="img-fluid py-2" alt="Descriptive alt text here" />
                                                <h5 className="card-title my-3"><strong>Investing in Real Estate Stocks</strong>
                                                </h5>
                                                <div className="mb-2">
                                                    Delve into the dynamic world of real estate stocks, uncovering opportunities
                                                    and
                                                    strategies for investors in the property market.
                                                </div>
                                                <div className="row py-4 d-flex align-items-center">
                                                    <div className="col-7">
                                                        <p className="author-text">Author: Michael D. Ashley</p>
                                                    </div>
                                                    <div className="col-5">
                                                        <a className="blog-link" href="/sectors/investing-realestate.html">Read
                                                            More&nbsp;&nbsp;</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <div className="mb-2">
                                            <div className="card-body px-4 py-2 bg-white h-100">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/makemoney-2eb29.appspot.com/o/richiest%2Fposts%2Fblog1%2FDALL%C2%B7E%202023-12-05%2013.45.07%20-%20A%20high-resolution%20image%20of%20a%20casually%20dressed%20adult%20Middle-Eastern%20woman%20with%20long%20dark%20hair%2C%20sitting%20in%20a%20comfortable%20chair%2C%20holding%20a%20laptop.%20The%20wo.png?alt=media&token=d59062ef-8e1c-413c-8307-0af8cc186c43"
                                                    className="img-fluid py-2" alt="Descriptive alt text here" />
                                                <h5 className="card-title my-3"><strong>Investing in Healthcare Stocks</strong></h5>
                                                <div className="mb-2">
                                                    Navigate the healthcare stock landscape with insights into trends, risks,
                                                    and
                                                    opportunities in this vital and ever-evolving sector.
                                                </div>
                                                <div className="row py-4 d-flex align-items-center">
                                                    <div className="col-7">
                                                        <p className="author-text">Author: Michael D. Ashley</p>
                                                    </div>
                                                    <div className="col-5">
                                                        <a className="blog-link" href="/sectors/investing-healthcare.html">Read
                                                            More&nbsp;&nbsp;</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3 justify-content-center">
                                <div className="col-auto">
                                    <a className="btn bg-dark px-5" href="/articles.html">More</a>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row dark-div py-5 m-0">
                        <div className="col">
                            <Footer />

                        </div>
                    </div>
                </div>

                <script src="https://code.jquery.com/jquery-3.5.1.min.js"></script>

                <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.10.2/dist/umd/popper.min.js"></script>
                <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>


            </div>
        </div>
    );
};

export default Home;
