import React, { useState } from 'react';
import api from '../api/api';

const Subscribe = () => {
    const [credentials, setCredentials] = useState({ name: "", email: "" });

    // Handle input change
    const handleChange = (e) => {
        setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            console.log("credentials are ", credentials);
            const response = await api.post('/api/subscriber/subscribe', credentials);
            console.log(response.data); // Handle success response
        } catch (error) {
            console.error(error); // Handle submission errors
        }
    };
    return (
        <div className="row py-5 px-md-5 px-sm-1 align-items-center">
            <div className="col-md-6  col-sm-12 text-white">
                <h1 className=""><strong>Ready to Build Wealth?</strong></h1>
                <p className="">Get dividend stock picks, research and news in your inbox each week.</p>
            </div>
            <div className="col-md-6 col-sm-12 text-white">
                <form className="w-50 mx-auto" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            id="name"
                            type="text"
                            className="form-control border border-white dark-div bg-dark text-white"
                            placeholder="Name"
                            onChange={handleChange} // Add event handler
                        />
                    </div>
                    <div className="form-group">
                        <input
                            id="email"
                            type="email"
                            className="form-control border border-white dark-div bg-dark text-white"
                            placeholder="Email"
                            onChange={handleChange} // Add event handler
                        />
                    </div>
                    <button type="submit" className="btn w-100 bg-dark bg-dark border border-white text-white">
                        Subscribe
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Subscribe;
