// src/components/About.js
import React from 'react';

const Nav = () => {
    return (
        <nav className="navbar navbar-expand-lg bg-white navbar-light text-sm">
            <a className="navbar-brand" href="/"><strong>Richiest.com</strong></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown mr-3 position-static">
                        <a className="nav-link dropdown-toggle" href="#" id="ETFsDropdown" role="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            Bond ETFs
                        </a>
                        <div className="dropdown-menu w-100" aria-labelledby="ETFsDropdown">
                            <div className="row ml-2">
                                <span><strong>Bond ETFs</strong></span>
                            </div>
                            <div className="row">
                                <a className="dropdown-item" href="/stocks/dividend/bil.html">BIL - the SPDR Bloomberg 1-3 Month
                                    T-Bill ETF</a>
                            </div>
                        </div>
                    </li>
                    {/* <!-- Dividend Stocks Dropdown Menu --> */}
                    <li className="nav-item dropdown mr-3 position-static">
                        <a className="nav-link dropdown-toggle" href="#" id="ETFsDropdown" role="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            Divident ETFs
                        </a>
                        <div className="dropdown-menu w-100" aria-labelledby="ETFsDropdown">
                            <div className="row ml-2">
                                <span><strong>Dividend ETFs</strong></span>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <a className="dropdown-item" href="/etfs/dividend/dgro.html">DGRO - the iShares Core Dividend
                                        Growth ETF</a>
                                    <a className="dropdown-item" href="/etfs/dividend/dgrw.html">DGRW - WisdomTree
                                        Global High Growth Dividend ETF</a>
                                    <a className="dropdown-item" href="/etfs/dividend/divo.html">DIVO - Amplify CWP Enhanced
                                        Dividend Income ETF</a>
                                    <a className="dropdown-item" href="/etfs/dividend/dln.html">DLN - the WisdomTree U.S. LargeCap
                                        Dividend Fund</a>
                                    <a className="dropdown-item" href="/etfs/dividend/don.html">DON - WisdomTree U.S. MidCap
                                        Dividend Fund</a>
                                    <a className="dropdown-item" href="/etfs/dividend/dvy.html">DVY - the iShares Select Dividend
                                        ETF</a>
                                    <a className="dropdown-item" href="/etfs/dividend/fvd.html">FVD - First Trust Value Line®
                                        Dividend Index Fund</a>
                                    <a className="dropdown-item" href="/etfs/dividend/hdv.html">HDV - iShares Core High Dividend
                                        ETF</a>
                                    <a className="dropdown-item" href="/etfs/dividend/jepi.html">JEPI - the JPMorgan Equity
                                        Premium Income ETF </a>
                                    <a className="dropdown-item" href="/etfs/dividend/jepq.html">JEPQ - JPMorgan Nasdaq Equity
                                        Premium Income ETF</a>
                                    <a className="dropdown-item" href="/etfs/dividend/nobl.html">NOBL - ProShares S&P 500 Dividend
                                        Aristocrats ETF</a>
                                </div>
                                <div className="col-md-6">
                                    <a className="dropdown-item" href="/etfs/dividend/rdvy.html">RDVY - First Trust Rising
                                        Dividend Achievers ETF</a>
                                    <a className="dropdown-item" href="/etfs/dividend/schd.html">SCHD - Schwab US Dividend Equity
                                        ETF</a>
                                    <a className="dropdown-item" href="/etfs/dividend/sdy.html">SDY - SPDR® S&P® Dividend
                                        Aristocrats™ ETF</a>
                                    <a className="dropdown-item" href="/etfs/dividend/sphd.html">SPHD - Invesco S&P 500 High
                                        Dividend Low Volatility ETF</a>
                                    <a className="dropdown-item" href="/etfs/dividend/vdy.html">VDY - Vanguard FTSE Canadian High
                                        Dividend Yield Index ETF</a>
                                    <a className="dropdown-item" href="/etfs/dividend/vig.html">VIG - Vanguard Dividend
                                        Appreciation Index Fund ETF</a>
                                    <a className="dropdown-item" href="/etfs/dividend/vti.html">VTI - the Vanguard Total Stock
                                        Market ETF</a>
                                    <a className="dropdown-item" href="/etfs/dividend/vym.html">VYM -Vanguard High Dividend Yield
                                        ETF</a>
                                    <a className="dropdown-item" href="/etfs/dividend/vymi.html">VYMI - the Vanguard International
                                        High Dividend Yield ETF</a>
                                </div>
                            </div>

                        </div>
                    </li>
                    <li className="nav-item dropdown mr-3 position-static">
                        <a className="nav-link dropdown-toggle" href="#" id="dividendStocksDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Dividend Stocks
                        </a>
                        <div className="dropdown-menu w-100" aria-labelledby="dividendStocksDropdown">
                            <div className="row ml-2">
                                <span><strong>Dividend Stocks</strong></span>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <a className="dropdown-item" href="/stocks/dividend/aapl.html">AAPL - Apple</a>
                                    <a className="dropdown-item" href="/stocks/dividend/abbv.html">ABBV - AbbVie</a>
                                    <a className="dropdown-item" href="/stocks/dividend/agnc.html">AGNC - AGNC Investment Corp.</a>
                                    <a className="dropdown-item" href="/stocks/dividend/arcc.html">ARCC - Ares Capital
                                        Corporation</a>
                                    <a className="dropdown-item" href="/stocks/dividend/ctra.html">CTRA - Cotera Energy</a>
                                    <a className="dropdown-item" href="/stocks/dividend/cvs.html">CVS - CVS Health</a>
                                    <a className="dropdown-item" href="/stocks/dividend/epr.html">EPR - EPR Properties</a>
                                    <a className="dropdown-item" href="/stocks/dividend/ibm.html">IBM - International Business
                                        Machines Corp.</a>
                                    <a className="dropdown-item" href="/stocks/dividend/infy.html">INFY - Infosys Ltd.</a>
                                    <a className="dropdown-item" href="/stocks/dividend/jnj.html">JNJ - Johnson & Johnson</a>
                                    <a className="dropdown-item" href="/stocks/dividend/key.html">KEY - KeyCorp</a>
                                    <a className="dropdown-item" href="/stocks/dividend/ko.html">KO - Coca-Cola</a>
                                    <a className="dropdown-item" href="/stocks/dividend/mo.html">MO - Altria Group, Inc.</a>
                                    <a className="dropdown-item" href="/stocks/dividend/mplx.html">MPLX - MPLX LP</a>

                                </div>
                                <div className="col-md-6">
                                    <a className="dropdown-item" href="/stocks/dividend/msft.html">MSFT - Microsoft</a>
                                    <a className="dropdown-item" href="/stocks/dividend/o.html">O - Realty Income</a>
                                    <a className="dropdown-item" href="/stocks/dividend/oke.html">OKE - ONEOK, Inc.</a>
                                    <a className="dropdown-item" href="/stocks/dividend/pg.html">PG - Procter & Gamble</a>
                                    <a className="dropdown-item" href="/stocks/dividend/pxd.html">PXD - Pioneer Natural
                                        Resources</a>
                                    <a className="dropdown-item" href="/stocks/dividend/sblk.html">SBLK - Star Bulk Carriers</a>
                                    <a className="dropdown-item" href="/stocks/dividend/sjt.html">SJT - San Juan Basin Royalty
                                        Trust</a>
                                    <a className="dropdown-item" href="/stocks/dividend/t.html">T - AT&T</a>
                                    <a className="dropdown-item" href="/stocks/dividend/vici.html">VICI - VICI Properties</a>
                                    <a className="dropdown-item" href="/stocks/dividend/vz.html">VZ - Verizon</a>
                                    <a className="dropdown-item" href="/stocks/dividend/wmt.html">WMT - Walmart</a>
                                    <a className="dropdown-item" href="/stocks/dividend/xom.html">XOM - ExxonMobil</a>
                                    <a className="dropdown-item" href="/stocks/dividend/zim.html">ZIM - ZIM Integrated Shipping
                                        Services
                                        Ltd</a>


                                    {/* <!-- Add more ticker symbols as needed --> */}
                                </div>
                            </div>
                        </div>



                        {/* <!-- End of Dividend Stocks Dropdown Menu -->
                    <!-- Company Size Dropdown Menu -->
                    <!-- <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="companySizeDropdown" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Market Capitalization
                </a>
                <div className="dropdown-menu" aria-labelledby="companySizeDropdown">
                    <a className="dropdown-item" href="#mega-cap">Mega-Cap</a>
                    <a className="dropdown-item" href="#large-cap">Large-Cap</a>
                    <a className="dropdown-item" href="#mid-cap">Mid-Cap</a>
                    <a className="dropdown-item" href="#small-cap">Small-Cap</a>
                    <a className="dropdown-item" href="#micro-cap">Micro-Cap</a>
                    <a className="dropdown-item" href="#nano-cap">Nano-Cap</a>
                </div>
             -->
                    <!-- End of Company Size Dropdown Menu -->
                    <!-- Sectors Dropdown Menu --> */}
                    </li>
                    <li className="nav-item dropdown mr-3 position-static">
                        <a className="nav-link dropdown-toggle" href="#" id="sectorsDropdown" role="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            Sectors
                        </a>
                        <div className="dropdown-menu w-100" aria-labelledby="sectorsDropdown">
                            <div className="row ml-2">
                                <span><strong>Sectors</strong></span>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <a className="dropdown-item" href="/sectors/investing-basicmaterials.html">Basic
                                        Materials</a>
                                    <a className="dropdown-item" href="/sectors/investing-communicationservices.html">Communication
                                        Services</a>
                                    <a className="dropdown-item" href="/sectors/investing-consumercyclical.html">Consumer
                                        Cyclicals</a>
                                    <a className="dropdown-item" href="/sectors/investing-consumerdefensive.html">Consumer
                                        Defensive</a>
                                    <a className="dropdown-item" href="/sectors/investing-energy.html">Energy</a>
                                    <a className="dropdown-item" href="/sectors/investing-financial.html">Financial</a>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <a className="dropdown-item" href="/sectors/investing-healthcare.html">Healthcare</a>
                                    <a className="dropdown-item" href="/sectors/investing-industrials.html">Industrials</a>

                                    {/* <!-- <a className="dropdown-item" href="#industrial-goods">Industrial Goods</a> --> */}
                                    <a className="dropdown-item" href="/sectors/investing-realestate.html">Real
                                        Estate</a>
                                    <a className="dropdown-item" href="/sectors/investing-technology.html">Technology</a>
                                    <a className="dropdown-item" href="/sectors/investing-utilities.html">Utilities</a>
                                    {/* <!-- <a className="dropdown-item" href="#consumer-services">Consumer Services</a>
                                    <a className="dropdown-item" href="#oil-and-gas">Oil & Gas</a>
                                    <a className="dropdown-item" href="#telecommunications">Telecommunications</a> --> */}
                                </div>
                            </div>
                        </div>

                        {/* <!-- End of Sectors Dropdown Menu --> */}
                    </li>

                    <li className="nav-item mr-3">
                        <a className="nav-link" href="/articles.html">Articles</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/about.html">About Us</a>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Nav;
