import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { api } from "../api/api.js";

const Activate = () => {
    const { hash } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    function isValidHash(hash) {
        // Basic length check
        if (hash.length !== 64) { // Assuming 32-byte hash, represented as a 64-character hex string
            return false;
        }

        // Regex pattern for valid hexadecimal characters (adjust if needed)
        const hexRegex = /^[0-9a-f]+$/;
        return hexRegex.test(hash);
    }

    const activate = async () => {
        console.log("activate")
        try {
            const response = await api.post(`/api/subscriber/activate`, { hash });
            if (response.status === 200) {
                setSuccessMessage("Your subscription is now active!");

            } else {
                setErrorMessage("Activation failed");
            }
        } catch (error) {
            if (!error.response) {
                setErrorMessage("No server response");
            } else {
                setErrorMessage("Activation failed"); // Consider more specific error handling
                console.error(error); // Log detailed error for debugging
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        async function fetchData() {
            if (isValidHash(hash)) {
                console.log("fetchData - isValidHash")
                await activate();
            } else {
                setErrorMessage("Invalid activation link");
                setLoading(false); // Set loading to false in case of an invalid hash
            }
        }

        fetchData();
    }, [hash]);

    return (
        <div className="bg-light">
            <div className="container-1280">
                <div className="row dark-div py-5 m-0">
                    <div className="col text-center p-4">
                        <h1 className="text-white"><strong>Be the Richiest you can</strong></h1>
                    </div>
                </div>
                <div className="p-0 m-0 py-5">
                    <div className="row">
                        <div className="col text-center">
                            <h5 className="">{loading}</h5><br /><br />
                            <h5 className="">{successMessage}</h5><br /><br />
                            <h5 className="">{errorMessage}</h5><br /><br />
                            <h5 className="lead"><a href="https://richiest.com">Return Home</a></h5>
                        </div>
                    </div>
                </div>
                <div className="row dark-div py-5 m-0">
                    <div className="col">

                    </div>
                </div>
            </div >
        </div >
    );
};

export default Activate;
